function iniPayAutosubmit(PV_MID,
  PV_OID,
  PV_PAY_TYPE,
  PV_DEVICE_TYPE,
  PV_IDCCODE, PV_AMT,
  PV_GOODS,
  PV_UNAME,
  PV_TIMESTAMP,
  PV_NEXT_URL,
  PV_NOTI_URL,
  PV_CLOSE_URL,
  PAYMENT_URL,
  PV_CHKFAKE,
  PV_NOTI,
  PV_CHARSET
) {
  var url = PAYMENT_URL;                // 가맹점 DOMAIN 예시
  var obj = {
    "P_MID": PV_MID,   // 가맹점 아이디                	EX) EDGETestId
    "P_OID": PV_OID,   // 가맹점 주문번호(Unique 값)   	    EX) EDGETestId202309081631579
    "P_PAY_TYPE": PV_PAY_TYPE,   // 결제수단                     	EX) CARD, VBANK, BANK, ...
    "P_DEVICE_TYPE": PV_DEVICE_TYPE,   // 디바이스타입                 	EX) WEB, MOBILE
    "P_IDCCODE": PV_IDCCODE,   // IDC 센터 코드 사용           	EX) Y 고정
    "P_AMT": PV_AMT,   // 결제금액
    "P_GOODS": PV_GOODS,   // 상품명
    "P_UNAME": PV_UNAME,   // 구매자명
    "P_NEXT_URL": PV_NEXT_URL,   // 인증 결과 수신 URL
    "P_NOTI_URL": PV_NOTI_URL,   // 가상계좌 입금통보 URL
    "P_CLOSE_URL": PV_CLOSE_URL,   // 결제창 닫기 요청 URL
    "P_CHARSET": PV_CHARSET,   // 결과 인코딩 옵션
    "P_NOTI": PV_NOTI,   // 가맹점 임의 데이터 
    "P_TIMESTAMP": PV_TIMESTAMP,   // 타임스탬프
    "P_CHKFAKE": PV_CHKFAKE,   // 위변조 검증 데이터
  };
  /* SDK 함수호출 */
  INIPayPro.requestPayment(obj);
}